"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.max = exports.min = exports.calculate = void 0;
/** Evaluate the string operation without relying on eval */
function calculate(string) {
    function applyOperator(left, op, right) {
        if (op === '+')
            return left + right;
        else if (op === '-')
            return left - right;
        else if (op === '*')
            return left * right;
        else if (op === '/')
            return left / right;
        else
            return right || left;
    }
    function evaluate(root) {
        switch (root.type) {
            case 'group': return evaluate(root.right);
            case 'additive':
            case 'multiplicative':
                return applyOperator(evaluate(root.left), root.operation, evaluate(root.right));
            case 'number': return parseFloat(root.value);
        }
    }
    const rootNode = { type: 'group' };
    let currentNode = rootNode;
    function openGroup() {
        const newGroup = { type: 'group', parent: currentNode };
        currentNode.right = newGroup;
        currentNode = newGroup;
    }
    function closeGroup() {
        while (currentNode.type !== 'group')
            currentNode = currentNode.parent;
        currentNode = currentNode.parent;
    }
    function addNumber(char) {
        const currentNumber = currentNode.right;
        if (currentNumber === undefined)
            currentNode.right = { type: 'number', value: char };
        else
            currentNumber.value += char;
    }
    function addOperator(char) {
        const additive = '+-'.includes(char);
        const priority = additive ? 1 : 2;
        // If it is a sign and not an operation, we add it to the comming number
        if (additive && !currentNode.right)
            return addNumber(char);
        while (currentNode.priority && (currentNode.priority >= priority))
            currentNode = currentNode.parent;
        const operator = {
            type: additive ? 'additive' : 'multiplicative',
            priority,
            parent: currentNode,
            operation: char,
            left: currentNode.right
        };
        currentNode.right = operator;
        currentNode = operator;
    }
    string.split('').forEach(char => {
        if (char === '(')
            openGroup();
        else if (char === ')')
            closeGroup();
        else if ('0123456789.'.includes(char))
            addNumber(char);
        else if ('+*-/'.includes(char))
            addOperator(char);
    });
    return evaluate(rootNode);
}
exports.calculate = calculate;
function min(string) {
    const values = string.split(',').map(val => parseFloat(val.trim()));
    return Math.min(...values);
}
exports.min = min;
function max(string) {
    const values = string.split(',').map(val => parseFloat(val.trim()));
    return Math.max(...values);
}
exports.max = max;
