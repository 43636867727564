"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createMedia = exports.createContext = void 0;
const convertUnits_1 = require("../convertUnits");
const react_native_1 = require("react-native");
function createContext(units) {
    const vw = (units.vw || 1) * 100;
    const vh = (units.vh || 1) * 100;
    return {
        anyHover: 'hover',
        anyPointer: react_native_1.Platform.OS === 'web' ? 'fine' : 'coarse',
        aspectRatio: vw / vh,
        color: 16,
        colorGamut: 'srgb',
        colorIndex: 0,
        deviceAspectRatio: vw / vh,
        deviceHeight: vh,
        deviceWidth: vw,
        dynamicRange: 'standard',
        environmentBlending: 'opaque',
        forcedColor: 'none',
        grid: 0,
        height: vh,
        hover: 'hover',
        invertedColors: 'none',
        monochrome: 0,
        orientation: vw > vh ? 'landscape' : 'portrait',
        overflowBlock: 'scroll',
        overflowInline: 'scroll',
        pointer: 'coarse',
        prefersColorScheme: 'dark',
        prefersContrast: 'no-preference',
        prefersReducedData: 'no-preference',
        prefersReducedMotion: 'no-preference',
        prefersReducedTransparency: 'no-preference',
        resolution: react_native_1.PixelRatio.getPixelSizeForLayoutSize(vw),
        scan: 'progressive',
        scripting: 'enabled',
        type: 'screen',
        units,
        update: 'fast',
        width: vw
    };
}
exports.createContext = createContext;
function convertAnyValue(key, value, units) {
    if (key === 'resolution') {
        // Convert density
        if (value === 'infinite')
            return Infinity;
        const densityUnitsEquivalence = {
            dpi: 'in',
            dpcm: 'cm',
            dppx: 'px',
            x: 'px'
        };
        const [num, unit] = convertUnits_1.parseValue(value);
        return num + densityUnitsEquivalence[unit];
    }
    else if (key === 'deviceAspectRatio' || key === 'aspectRatio') {
        // Convert ratio
        const [w, h] = value.split('/').map(v => parseInt(v, 10));
        return w / h;
    }
    return convertUnits_1.convertValue(key, value, units);
}
/** Check if a constraint is respected by the provided context */
function evaluateConstraint(constraint, context) {
    return Object.keys(constraint).every(key => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const [, baseKey, minMax] = key.match(/(.*?)(Min|Max|$)/);
        const value = convertAnyValue(baseKey, constraint[key] + '', context.units);
        if (minMax === 'Min') {
            return context[baseKey] >= value;
        }
        else if (key.endsWith('Max')) {
            return context[baseKey] <= value;
        }
        else if (['all', 'sprint', 'speech', 'screen'].includes(key)) {
            return context.type === key || key === 'all';
        }
        else {
            // Boolean check: we want the value to be defined and not equal to 'none'
            if (value === undefined)
                return !!context[baseKey] && context[baseKey] !== 'none';
            // float comparison
            if (baseKey.endsWith('aspectRatio'))
                return Math.abs(context[baseKey] - value) < (value + context[baseKey]) / 100;
            return context[baseKey] === value;
        }
    });
}
/** Parse media query constraint such as min-width: 600px, or screen */
function parseConstraintValue(constraintString) {
    let [key, value] = constraintString.split(':').map(v => v.trim());
    if (key.startsWith('min-'))
        key = key.substring(4) + 'Min';
    else if (key.startsWith('max-'))
        key = key.substring(4) + 'Max';
    const constraint = { [key]: value };
    return (context) => evaluateConstraint(constraint, context);
}
function parse(constraint, previous) {
    const result = constraint.match(/\sand\s|,|\sonly\s|\(|\snot\s/ims);
    if (!result) {
        // If we reached the end of the string, we just return the last constraint
        if (constraint.match(/\w/))
            return parseConstraintValue(constraint);
        // If there is just an empty string, we just ignore it by returning a truthy evaluation
        else
            return previous || (() => true);
    }
    const token = result[0]; // The next command we found
    const tail = constraint.substring(result.index + token.length); // The rest of the constraint
    const current = constraint.substring(0, result.index); // The current constraint
    if (token === '(') {
        try {
            const { index } = tail.match(/\)/);
            const parenthesis = tail.substring(0, index);
            const postParenthesis = tail.substring(index + 1);
            return parse(postParenthesis, parse(parenthesis, previous));
        }
        catch (err) {
            console.error('No matching parenthesis in the media query', constraint);
            throw err;
        }
    }
    else if (token.includes('and')) {
        const left = previous || parseConstraintValue(current);
        const right = parse(tail);
        return (context) => left(context) && right(context);
    }
    else if (token.includes('not')) {
        const evaluate = parse(tail);
        return (context) => !evaluate(context);
    }
    else if (token.includes('only')) {
        return parse(tail, previous || parseConstraintValue(current));
    }
    else if (token === ',') {
        const left = previous || parseConstraintValue(current);
        const right = parse(tail);
        return (context) => left(context) || right(context);
    }
    else {
        throw new Error(`Error while parsing media query '${constraint}'. No token found`);
    }
}
const createMedia = (query) => {
    const parsed = query.match(/@media(.*?){([^{}]*)}/mis);
    if (!parsed)
        throw new Error(`Parsing error: check the syntax of media query ${query}.`);
    const [, constraints, css] = parsed;
    const isValid = parse(constraints);
    return {
        css,
        isValid
    };
};
exports.createMedia = createMedia;
